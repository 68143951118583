import React, {useState} from "react";
import {Container} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import Header from "../components/Header";
import Table from "../components/Table";
import {apiGet, useMount, usePage} from "../utils";
import usersPage from "../atoms/usersPage";
import {LinkTo} from "../components/LinkTo";

export default function Users() {
    const [rows, setRows] = useState([])
    const [error, setError] = React.useState('')
    const page = usePage(usersPage)

    const [success] = React.useState(page.success)

    useMount(() => {
        apiGet('users').then((rows) => {
            setRows(rows)
        }).catch(e => {
            console.error(e)
            setError(e.error)
        })
    })

    return (
        <div className="component">
            <Header />
            <div className="wrapper" style={{marginTop: '30px'}}>
                <Container maxWidth="lg" sx={{mt: 5, mb: 4}}>
                    {success && <Alert style={{marginBottom: '10px'}} severity="success">{success}</Alert>}
                    {error && <Alert style={{marginBottom: '10px'}} severity="error">{error}</Alert>}
                    <Table
                        title="Users"
                        rows={rows}
                        defaultRowsPerPage={10}
                        defaultRowsPerPageOptions={[10, 25, 50, 100]}
                        columns={[
                            {
                                id: 'name',
                                align: 'left',
                                label: 'Name',
                                sortable: true,
                                default: 'asc',
                                render: (name, row) => {
                                    return <LinkTo to={`/users/${row.username}`}>{name}</LinkTo>
                                },
                                searchValue(name) {
                                    return name
                                }
                            },
                            {
                                id: 'email',
                                align: 'left',
                                label: 'E-mail',
                                sortable: true
                            },
                            {
                                id: 'phone',
                                align: 'left',
                                label: 'Phone',
                                sortable: true,
                            },
                            {
                                id: 'enabled',
                                align: 'left',
                                label: 'Enabled',
                                sortable: true,
                                render: (value) => {
                                    return value ? <strong>Yes</strong> : 'No'
                                },
                                searchValue(value) {
                                    return value ? 'Yes': 'No'
                                }
                            },
                            {
                                id: 'cognito_status',
                                align: 'left',
                                label: 'Cognito status',
                                sortable: true,
                            },
                        ]}
                    />
                </Container>
            </div>
        </div>
    )
}
