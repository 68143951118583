import i18n from "i18next";
import {initReactI18next, useTranslation} from "react-i18next";
import {apiGet, apiPost, useMount} from "./utils";
import * as React from "react";
import {useCookies} from "react-cookie";
import {addYears} from "date-fns";

// Synchonously load the default locale bundles for both `i18next` and `date-fns`.
import { enUS as defaultDateFnsLocaleBundle } from "date-fns/locale";
import defaultI18nResourceBundle from "@i18n/en/frontend.json";

const nullValue = Symbol('fetching')

/** The default locale's ISO 639-1 code. */
const DEFAULT_LOCALE_KEY = "en";
/** The default locale's BCP 47 code for use in `date-fns`. */
const DEFAULT_DATE_FNS_LOCALE_KEY = "en-US";

/**
 * Loads an `i18next` translation file for the given locale and switches to it.
 * @param locale The locale to switch to.
 */
async function switchLocale(locale) {
    const resourceBundle = (
        await import(
            /* webpackChunkName: "i18n-[index]" */
            `@i18n/${locale}/frontend.json`
            )
    ).default;
    await i18n.addResourceBundle(locale, "translation", resourceBundle, true, true).changeLanguage(locale);
}

/**
 * Loads a `date-fns` locale object for the given locale, or the default locale
 * if a locale is not provided.
 */
async function loadDateFnsLocale(locale = DEFAULT_DATE_FNS_LOCALE_KEY) {
    const localeBundle = (
        await import(
            /* webpackChunkName: "date-fns-[index]" */
            `date-fns/locale/${locale}`
            )
    ).default;

    return localeBundle;
}

function changeI18nLanguage(locale, locales, setCookie, setCurrentLanguage, setCurrentLanguageFnsLocale) {
    const localeData = locales.find(l => l.key === locale)
    const dateFnsLocale = localeData && localeData.dateFnsLocale ? localeData.dateFnsLocale : DEFAULT_DATE_FNS_LOCALE_KEY

    return switchLocale(locale).then(() => {
        const domain = window.location.hostname.split('.').slice(-2).join('.'); // extract the last two parts of the domain by splitting on '.'
        setCurrentLanguage(locale)
        setCookie('language', locale, {path: '/', expires: addYears(new Date(), 1), sameSite: 'strict', domain})
        return loadDateFnsLocale(dateFnsLocale).then(localeBundle => {
            setCurrentLanguageFnsLocale(localeBundle)
        })
    })
}

let I18nContext = React.createContext(null);

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: DEFAULT_LOCALE_KEY,
        debug: process.env.NODE_ENV === "development",
        resources: { [DEFAULT_LOCALE_KEY]: { translation: defaultI18nResourceBundle } },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

function I18nProvider({children}) {
    const [, setCookie] = useCookies(['language']);
    const [currentLanguage, setCurrentLanguage] = React.useState(nullValue);
    const [currentLanguageFnsLocale, setCurrentLanguageFnsLocale] = React.useState(defaultDateFnsLocaleBundle);
    const [languages, setLanguages] = React.useState([]);
    const {t} = useTranslation();

    const ready = currentLanguage !== nullValue

    useMount(() => {
        if (!ready) {
                apiGet('languages').then(response => {
                setLanguages(response.locales)
                return changeI18nLanguage(response.locale, response.locales, setCookie, setCurrentLanguage, setCurrentLanguageFnsLocale)
            }).catch(e => {
                console.error(e)
            })
        }
    }, [ready]);

    let changeLanguage = (language, authenticated = false) => {
        if (authenticated) {
            return apiPost('change-language', {language}).then(() => {
                return changeI18nLanguage(language, languages, setCookie, setCurrentLanguage, setCurrentLanguageFnsLocale)
            })
        } else {
            return changeI18nLanguage(language, languages, setCookie, setCurrentLanguage, setCurrentLanguageFnsLocale)
        }
    };

    let value = {
        currentLanguage,
        currentLanguageFnsLocale,
        languages,
        ready,
        changeLanguage,
        t,
        trans: (prefix) => {
            return (key, options = {}) => {
                return t(`${prefix}.${key}`, options)
            }
        }
    };

    return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
}

function useI18n() {
    return React.useContext(I18nContext);
}

export {i18n, I18nProvider, useI18n, I18nContext};
