import React from "react";
import {
    Box,
    Button,
    Container,
    FormHelperText,
    MenuItem,
    Paper,
    TextField,
    Typography
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {apiDelete, apiGet, apiPost, createClearError, useMount} from "../utils";
import Header from "../components/Header";
import {useHistory, useParams} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import usersPage from "../atoms/usersPage";
import {useAuth} from "../auth";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {useI18n} from "../i18n";

export default function User() {
    const history = useHistory();
    const params = useParams();
    const {languages} = useI18n()

    const {profile, reload} = useAuth()

    const [loading, setLoading] = React.useState(true)

    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [language, setLanguage] = React.useState('')
    const [can_reset, setCanReset] = React.useState(false)
    const [can_delete, setCanDelete] = React.useState(false)

    const [error, setError] = React.useState('')
    const [errors, setErrors] = React.useState({})

    const setUsersPage = useSetRecoilState(usersPage)

    const clearError = createClearError(errors, setErrors)

    useMount(() => {
        apiGet(`users/${params.username}`).then((user) => {
            setName(user.name)
            setEmail(user.email)
            setPhone(user.phone)
            setLanguage(user.language)
            setCanReset(user.can_reset)
            setCanDelete(user.can_delete)

            setError('')
            setErrors({})

            setLoading(false)
        }).catch(({errors, error}) => {
            setError(error)
            setErrors(errors)
        })
    })

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            name,
            email,
            phone,
            language,
        }

        setLoading(true);

        apiPost(`users/${params.username}`, data).then(() => {
            if (profile.username === params.username) {
                reload().then(() => {
                    setUsersPage({success: 'User saved'})
                    history.push('/users')
                })
            } else {
                setUsersPage({success: 'User saved'})
                history.push('/users')
            }
        }).catch(({errors, error}) => {
            setError(error)
            setErrors(errors)
        }).finally(() => {
            setLoading(false)
        })
    };

    const deleteUser = (event) => {
        event.preventDefault();

        if (window.confirm('Delete user?')) {
            setLoading(true);
            apiDelete(`users/${params.username}`).then(() => {
                setUsersPage({success: 'User deleted'})
                history.push('/users')
            }).catch(({error}) => {
                window.scrollTo(0, 0)
                setLoading(false)
                setError(error)
            })
        }
    };

    const resetPassword = (event) => {
        event.preventDefault();


        if (can_reset && window.confirm('Reset & send temporary password to user?')) {
            setLoading(true);
            apiPost(`users/${params.username}/reset`).then(() => {
                setUsersPage({success: 'Temporary password created and sent to user'})
                history.push('/users')
            }).catch(({error}) => {
                window.scrollTo(0, 0)
                setLoading(false)
                setError(error)
            })
        }
    };

    return (
        <div className="component">
            <Header/>
            <div className="wrapper" style={{marginTop: '30px'}}>
                <Container maxWidth="xs" sx={{mt: 5, mb: 4}} style={{position: 'relative'}}>
                    <Paper component="form" onSubmit={handleSubmit} noValidate sx={{my: {xs: 3, md: 3}, p: {xs: 2, md: 3}}} style={{padding: '20px'}}>
                        <Typography component="h1" variant="h6">Edit user</Typography>
                        <Box sx={{mt: 1}}>
                            {error && <Alert severity="error">{error}</Alert>}
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Name"
                                name="name"
                                value={name}
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                                disabled={loading}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    clearError('name')
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="E-mail"
                                name="email"
                                value={email}
                                error={Boolean(errors.email)}
                                helperText={errors.email}
                                disabled={loading}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    clearError('email')
                                }}
                            />
                            <FormControl fullWidth className="form-language" error={Boolean(errors.language)}>
                                <InputLabel htmlFor="language">Language</InputLabel>
                                <Select
                                    value={language}
                                    disabled={loading}
                                    onChange={(e) => {
                                        setLanguage(e.target.value);
                                        clearError('language')
                                    }}

                                    inputProps={{
                                        name: 'language',
                                        id: 'language',
                                    }}
                                    fullWidth
                                >
                                    {
                                        languages.map(language => {
                                            return <MenuItem key={language.key} value={language.key}>{language.name}</MenuItem>
                                        })
                                    }
                                </Select>
                                {errors.language && <FormHelperText>{errors.language}</FormHelperText>}
                            </FormControl>
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Phone"
                                name="phone"
                                value={phone}
                                error={Boolean(errors.phone)}
                                helperText={errors.phone}
                                disabled={loading}
                                onChange={(e) => {
                                    setPhone(e.target.value);
                                    clearError('phone')
                                }}
                            />
                        </Box>

                        <Box sx={{display: 'flex', justifyContent: 'space-between'}} style={{marginTop: "20px"}}>
                            <Button
                                disabled={loading}
                                variant="contained"
                                type="submit"
                                sx={{mt: 2}}
                            >
                                Save
                            </Button>

                            <Button disabled={loading} sx={{mt: 2}} onClick={() => history.push('/users')}>
                                Cancel
                            </Button>
                        </Box>
                    </Paper>

                    <Box sx={{display: 'flex', justifyContent: 'space-around'}} style={{marginTop: "20px"}}>
                        <Button
                            disabled={loading || !can_delete}
                            variant="contained"
                            color="error"
                            onClick={deleteUser}
                        >
                            Delete
                        </Button>

                        <Button
                            disabled={loading || !can_reset}
                            variant="contained"
                            color="error"
                            onClick={resetPassword}
                        >
                            Reset password
                        </Button>
                    </Box>
                </Container>
            </div>
        </div>
    )
}
