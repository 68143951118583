import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

import {Grid, Container, Paper, Link} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

import {useAuth} from "../auth";
import {useI18n} from "../i18n";
import {usePage} from "../utils";
import dashboardPage from "../atoms/dashboardPage";
import Header from "../components/Header";

export default function Dashboard() {
    const {profile} = useAuth()
    const {t} = useI18n()
    const page = usePage(dashboardPage)
    const { search } = useLocation();

    const [warning, setWarning] = React.useState(page.warning)

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const url = searchParams.get('forbidden');
        if(url) {
            setWarning(t('idp.forbidden', {url}))
        }
    }, [t, page, search])

    return (
        <div className="component">
            <Header />
            <div className="wrapper" style={{marginTop: '30px'}}>
                <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                    {warning && <Alert style={{marginBottom: '10px'}} severity="warning">{warning}</Alert>}
                    {profile.projects.length === 0 && <Alert style={{marginBottom: '10px'}} severity="warning">No project available!</Alert>}
                    <Grid container spacing={3}>
                        {profile.projects.map((project, index) => {
                            return (
                                <Grid key={index} item xs={12} md={8} lg={3}>
                                    <Paper sx={{p: 2}} style={{textAlign: 'center'}}>
                                        <Link href={project.url}>{project.name}</Link>
                                    </Paper>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </div>
        </div>
    )
}
