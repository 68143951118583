import {apiGet, apiPost, useMount} from "./utils";
import * as React from "react";
import {useSetRecoilState} from "recoil";
import dashboardPage from "./atoms/dashboardPage";
import {useLocation} from "react-router-dom";

const nullValue = Symbol('fetching')

const isValidRedirect = (redirect, profile) => {
    if(!redirect || !profile) {
        return false
    }

    let url

    try {
        url = new URL(redirect);
    } catch (e) {
        return false // invalid url
    }

    return profile.projects.some(project => {
        return project.url === url.origin
    });
}

const checkInstanceProfile = (profile, location) => {
    return new Promise((resolve) => {
        const params = new URLSearchParams(document.location.search || (location.state && location.state.from && location.state.from.search));

        let redirect = params.get('redirect');

        if (!params.has('forbidden') && !redirect && profile.hasOwnProperty('projects') && profile.projects.length === 1 && !profile.developer) {
            redirect = profile.projects[0].url
        }

        if (isValidRedirect(redirect, profile)) {
            window.location.replace(redirect)
            resolve({})
        } else {
            resolve({profile})
        }
    })
}

const handleResponse = (response, location) => {
    if (!response.challenge) {
        return checkInstanceProfile(response.profile, location)
    } else {
        return Promise.resolve(response)
    }
}

let AuthContext = React.createContext(null);

function AuthProvider({children}) {
    const [profile, setProfile] = React.useState(nullValue);

    const setDashboardPage = useSetRecoilState(dashboardPage)

    const location = useLocation()

    const fetching = profile === nullValue
    const authenticated = !fetching && profile
    const developer = !fetching && authenticated && profile.developer

    useMount(() => {
        if (fetching) {
            apiGet('profile').then(response => {
                checkInstanceProfile(response, location).then(response => {
                    if (response.profile) {
                        setProfile(response.profile)
                    }
                    if (response.warning) {
                        setDashboardPage({warning: response.warning})
                    }
                })
            }).catch(() => {
                setProfile(null)
            })
        }
    }, [fetching]);

    let changePassword = data => {
        return apiPost('change-password', data).then(response => {
            return handleResponse(response, location).then(result => {
                setProfile(result.profile)
                return result
            })
        })
    };

    let reload = () => {
        return apiGet('profile').then(response => {
            setProfile(response)
        })
    };

    let login = (email, password, language) => {
        return apiPost('login', {email, password, language}).then(response => {
            return handleResponse(response, location).then(result => {
                if (result.profile) {
                    setProfile(result.profile)
                }
                return result
            })
        })
    };

    let logout = () => {
        return apiGet('logout').then(() => {
            setProfile(null)
        })
    };

    let value = {
        profile,
        authenticated,
        developer,
        fetching,
        reload,
        login,
        logout,
        changePassword
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
    return React.useContext(AuthContext);
}

export {AuthProvider, useAuth, AuthContext};
