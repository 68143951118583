import * as React from "react";
import {BrowserRouter as Router, Route, Switch, Redirect, useLocation} from "react-router-dom";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Error404 from "../pages/Error404";
import ResetPassword from "../pages/ResetPassword";
import {AuthProvider, useAuth} from "../auth";
import {I18nProvider, useI18n} from "../i18n";
import {RecoilRoot} from "recoil";
import Users from "../pages/Users";
import User from "../pages/User";
import {ThemeProvider} from "@material-ui/core"
import {createTheme} from '@material-ui/core/styles'

const theme = createTheme({
    palette: {
        primary: {
            main: "#2445A1",
        },
        secondary: {
            main: "#FFF",
            contrastText: '#2445A1'
        },
        error: {
            main: "#D0471C"
        },
        action: {
            disabled: '#FFF',
            disabledBackground: '#CCC'
        }
    },
    typography: {
        fontFamily: [
            'Roboto',
            'sans-serif',
        ].join(','),
        button: {
            fontSize: 12,
            fontWeight: 700
        },
        body1: {
            fontSize: 12
        },
        h6: {
            fontSize: 16,
            fontWeight: 700
        }
    },
    overrides: {
        MuiInput: {
            underline: {
                '&::before': {
                    borderBottom: '1px solid #666'
                }
            }
        },
        MuiInputBase: {
            root: {
                color: '#333',
                fontSize: 13,
                fontWeight: 600,
            },
            input: {
                '&::placeholder': {
                    color: '#666',
                    fontSize: 13,
                }
            }
        },
        MuiFormLabel: {
            root: {
                color: '#666',
                fontSize: 13,
                fontWeight: 600,
            }
        },
        MuiPaginationItem: {
            root: {
                fontSize: 12,
                fontWeight: 600
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: 10
            }
        },
        MuiButton: {
            root: {
                letterSpacing: 1.25
            },
            contained: {
                boxShadow: 'none'
            },
            containedPrimary: {
                borderBottom: '2px solid #172E6C',

                '&:disabled': {
                    borderBottom: '2px solid #9B9B9B'
                }
            },
        },
        MuiFab: {
            root: {
                boxShadow: 'none'
            },
            secondary: {
                '&:hover': {
                    backgroundColor: '#EEE'
                }
            }
        }
    }
})

function RequireAuth({children}) {
    let {authenticated, fetching} = useAuth();
    let {ready} = useI18n();

    let location = useLocation();

    if (fetching || !ready) {
        return null
    } else if (!authenticated) {
        return <Redirect to="/login" state={{from: location}} replace/>;
    }

    return children;
}

function RequireAdmin({children}) {
    let {authenticated, developer, fetching} = useAuth();
    let {ready} = useI18n();
    let location = useLocation();

    if (fetching || !ready) {
        return null
    } else if (!authenticated || !developer) {
        return <Redirect to="/dashboard" state={{from: location}}/>;
    }

    return children;
}

function RequireGuest({children}) {
    let {authenticated, fetching} = useAuth();
    let {ready} = useI18n();

    let location = useLocation();

    if (fetching || !ready) {
        return null
    } else if (authenticated) {
        return <Redirect to={(location.state && location.state.from) || '/dashboard'} state={{from: location}} replace/>;
    }

    return children;
}

function NavigateIf({guestTo, authenticatedTo}) {
    let {authenticated, fetching} = useAuth();
    let {ready} = useI18n();

    let location = useLocation();

    if (fetching || !ready) {
        return null
    } else if (authenticated) {
        return <Redirect to={authenticatedTo} state={{from: location}} replace/>;
    } else {
        return <Redirect to={guestTo} state={{from: location}} replace/>;
    }
}

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <RecoilRoot>
                <I18nProvider>
                    <AuthProvider>
                        <Router>
                            <Switch>
                                <Route exact path="/" children={<NavigateIf guestTo="/login" authenticatedTo="/dashboard"/>}/>

                                <Route exact path="/login" children={<RequireGuest><Login/></RequireGuest>}/>
                                {/* ==== Guest routes ==== */}
                                <Route exact path="/reset-password" children={<RequireGuest><ResetPassword/></RequireGuest>}/>

                                {/* ==== Dashboard ==== */}
                                <Route exact path="/dashboard" children={<RequireAuth><Dashboard/></RequireAuth>}/>

                                {/* ==== Users ==== */}
                                <Route exact path="/users" children={<RequireAdmin><Users/></RequireAdmin>}/>
                                <Route exact path="/users/:username" children={<RequireAdmin><User/></RequireAdmin>}/>

                                <Route path="*" children={<Error404/>}/>
                            </Switch>
                        </Router>
                    </AuthProvider>
                </I18nProvider>
            </RecoilRoot>
        </ThemeProvider>
    );
}
