import React from "react"
import {Link} from "@material-ui/core"
import {Link as RouterLink} from 'react-router-dom'

export function LinkTo(props) {
    const CustomLink = React.forwardRef((linkProps, ref) => (
        <RouterLink ref={ref} to={props.to} {...linkProps} />
    ))

    return <Link {...props} component={CustomLink}/>
}
