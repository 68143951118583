import {useEffect, useRef} from 'react';
import {useRecoilValue, useResetRecoilState} from "recoil";

if (!process.env.REACT_APP_API_URL) {
    throw new Error('REACT_APP_API_URL not configured!')
}

export const useMount = (effect, deps) => {
    const mounted = useRef(false);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            return effect();
        }

        return () => {
        };
    }, [mounted, effect, deps]);
};

export const usePage = (page) => {
    const value = useRecoilValue(page);
    const resetValue = useResetRecoilState(page);

    const hasValue = Boolean(value)

    useMount(() => {
        hasValue && resetValue()
    }, [hasValue])

    return value
}

export const apiGet = (path, params = null) => {
    let queryString = params ? `?${new URLSearchParams(params)}` : ''

    return fetch(`${process.env.REACT_APP_API_URL}/api/${path}${queryString}`, {
        mode: 'cors',
        credentials: 'include'
    }).then(r => {
        if (r.ok) {
            return r.json()
        } else {
            return new Promise((resolve, reject) => r.json().then(json => reject({json, status: r.status})))
        }
    }).catch(handleErrors)
}

const handleOk = r => {
    if (r.ok) {
        return r.json()
    } else {
        return new Promise((resolve, reject) => r.json().then(json => reject(json)))
    }
}

const handleErrors = r => {
    console.error(r)
    if (r.errors) {
        return Promise.reject({
            error: '',
            errors: Object.keys(r.errors).reduce((errors, key) => {
                errors[key] = r.errors[key][0]
                return errors
            }, {})
        })
    } else {
        return Promise.reject({
            error: (r.json && r.json.message) || r.message || 'Server error',
            errors: {}
        })
    }
}

export const apiPost = (path, data) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/${path}`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(handleOk).catch(handleErrors)
}

export const apiDelete = (path) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/${path}`, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'include',
    }).then(handleOk).catch(handleErrors)
}

export const createClearError = (errors, setErrors) => {
    return (key) => {
        if (errors.hasOwnProperty(key) && errors[key]) {
            setErrors({
                ...errors,
                [key]: ''
            })
        }
    }
}
