import * as React from 'react';
import {useEffect} from "react";
import {Button, FormHelperText, Link, TextField} from '@material-ui/core'
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {Alert} from "@material-ui/lab";
import {createClearError, usePage} from "../utils";
import {LinkTo} from "../components/LinkTo";
import LogoBox from "../components/LogoBox";
import {useAuth} from "../auth";
import {useI18n} from "../i18n";
import loginPage from "../atoms/loginPage";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function Login() {
    const {login, changePassword} = useAuth()
    const {currentLanguage, changeLanguage, languages, trans} = useI18n()

    const page = usePage(loginPage);
    const hasLoginEmail = Boolean(page.email)

    const [challenge, setChallenge] = React.useState(null)
    const [challenge_parameters, setChallengeParameters] = React.useState(null)
    const [email, setEmail] = React.useState(page.email || '')
    const [password, setPassword] = React.useState('')
    const [password_confirmation, setPasswordConfirmation] = React.useState('')
    const [language, setLanguage] = React.useState(currentLanguage)
    const [error, setError] = React.useState('')
    const [errors, setErrors] = React.useState({})

    const [message, setMessage] = React.useState(page.message || '')

    const clearError = createClearError(errors, setErrors)

    const handleSuccess = ({challenge, challenge_parameters}) => {
        setChallenge(challenge)
        setChallengeParameters(challenge_parameters)
        setPassword('')
        setPasswordConfirmation('')
        setError('')
        setErrors({})
        setMessage('')
    }

    const t = trans('login')
    const t2 = trans('change_password')

    useEffect(() => {
        document.body.classList.add('page-login')
        return () => {
            document.body.classList.remove('page-login')
        }
    }, []);

    const handleErrors = ({errors, error}) => {
        setError(error)
        setErrors(errors)
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (challenge === 'change_password') {
            changePassword({
                ...challenge_parameters,
                password,
                password_confirmation,
                language
            }).then(handleSuccess).catch(handleErrors)
        } else {
            login(email, password, language).then(handleSuccess).catch(handleErrors)
        }
    };

    const resetState = (event) => {
        event.preventDefault();

        setChallenge(null)
        setChallengeParameters(null)
        setPassword('')
        setPasswordConfirmation('')
        setError('')
        setErrors({})
    };

    const authorizeUrl = `${process.env.REACT_APP_API_URL}/oauth2/authorize`

    if (challenge === 'change_password') {
        return (
            <div className="wrapper">
                <section>
                    <LogoBox/>
                    <form className="form form--bordered" onSubmit={handleSubmit}>
                        <Link className="link-back" onClick={resetState} href="#">
                            <ArrowBackIcon/>
                        </Link>

                        <span className="form-title form-title--inline">{t2('title')}</span>

                        <p className="text--info">{t2('info_code')}</p>

                        {error && <Alert severity="error" style={{marginBottom: '10px'}}>{error}</Alert>}
                        {message && <Alert severity="info" style={{marginBottom: '10px'}}>{message}</Alert>}

                        <TextField
                            error={!!errors.password}
                            helperText={errors.password || ""}
                            label={t2('field.password.label')}
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                clearError('password')
                            }}
                            fullWidth
                        />

                        <TextField
                            error={!!errors.password_confirmation}
                            helperText={errors.password_confirmation || ""}
                            label={t2('field.password_confirmation.label')}
                            type="password"
                            value={password_confirmation}
                            onChange={(e) => {
                                setPasswordConfirmation(e.target.value);
                                clearError('password_confirmation')
                            }}
                            fullWidth
                        />

                        <Button variant="contained" size="large" color="primary" type="submit" style={{marginBottom: '10px'}}>{t2('button.save')}</Button>
                    </form>
                </section>
            </div>
        );
    }

    return (
        <div className="wrapper">
            <section>
                <LogoBox/>
                <form className="form form--bordered" onSubmit={handleSubmit}>
                    <span className="form-title text--center">{t('title')}</span>

                    {error && <Alert severity="error" style={{'marginBottom': '10px'}}>{error}</Alert>}
                    {message && <Alert severity="info" style={{'marginBottom': '10px'}}>{message}</Alert>}

                    <TextField
                        error={!!errors.email}
                        helperText={errors.email || ""}
                        label={t('field.email.label')}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            clearError('email')
                        }}
                        fullWidth
                        autoFocus={!hasLoginEmail}
                    />

                    <TextField
                        error={!!errors.password}
                        helperText={errors.password || ""}
                        label={t('field.password.label')}
                        type="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            clearError('password')
                        }}
                        fullWidth
                        autoFocus={hasLoginEmail}
                    />

                    {languages.length > 1 && <FormControl className="form-language" error={Boolean(errors.language)}>
                        <InputLabel htmlFor="language">{t('field.language.label')}</InputLabel>
                        <Select
                            value={language}
                            onChange={(e) => {
                                setLanguage(e.target.value);
                                changeLanguage(e.target.value)
                                clearError('language')
                            }}
                            inputProps={{
                                name: 'language',
                                id: 'language',
                            }}
                            fullWidth
                        >
                            {
                                languages.map(language => {
                                    return <MenuItem key={language.key} value={language.key}>{language.name}</MenuItem>
                                })
                            }
                        </Select>
                        {errors.language && <FormHelperText>{errors.language}</FormHelperText>}
                    </FormControl>}

                    <Button variant="contained" size="large" color="primary" type="submit" fullWidth>{t('button.login')}</Button>

                    <LinkTo to="/reset-password">{t('button.forgot_password')}</LinkTo>

                    <Link className="link-authorize" href={authorizeUrl}>{t('button.login_as_gitlab')}</Link>
                </form>
            </section>
        </div>
    );
}
