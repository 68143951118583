import Header from "../components/Header";
import React from "react";
import {useAuth} from "../auth";
import {useI18n} from "../i18n";
import {Button} from "@material-ui/core";

export default function Error404() {
    let {fetching} = useAuth();
    let {ready, trans} = useI18n();

    if(fetching || !ready) {
        return null
    }

    const t = trans('not_found');

    return (
        <div className="component">
            <Header/>
            <section>
                <div className="wrapper">
                    <div className="error-box">
                        <h1>{t('header_title')}</h1>
                        <img src="/img/small-city.png" alt="Small city logo"/>
                        <p className="p-large">{t('description')}</p>
                        <Button variant="contained"
                                color="primary"
                                size="large"
                                type="button"
                                onClick={() => document.location.reload()}
                                href=""
                                fullWidth
                                className="button-action"
                        >{t('refresh')}</Button>
                    </div>
                </div>
            </section>
        </div>
    )
}
