import React from "react"
import {Fab} from "@material-ui/core"
import {Link as RouterLink} from 'react-router-dom'

export function FabTo(props) {
    const CustomLink = React.forwardRef((linkProps, ref) => (
        <RouterLink ref={ref} to={props.to} {...linkProps} />
    ))

    return <Fab {...props} component={CustomLink}/>
}
