import React from "react"

export default function LogoBox() {
    return (
        <div className="logo-box">
            <img src="/img/logo.png" alt="SURVIOT"/>
            <span className="version-number text--right">V1.0.0</span>
        </div>
    )
}
