import * as React from 'react';
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {Button, TextField} from '@material-ui/core'
import {Alert} from "@material-ui/lab";
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {apiPost, createClearError} from "../utils";
import {LinkTo} from "../components/LinkTo";
import loginPage from "../atoms/loginPage";
import LogoBox from "../components/LogoBox";
import {useI18n} from "../i18n";

const RESET_PASSWORD_SENT_TEMPORARY = 'temporary';

export default function ResetPassword() {
    const history = useHistory();

    const {trans} = useI18n()
    const setLoginPage = useSetRecoilState(loginPage);

    const [step, setStep] = React.useState(1)
    const [email, setEmail] = React.useState('')
    const [code, setCode] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [password_confirmation, setPasswordConfirmation] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [error, setError] = React.useState('')
    const [errors, setErrors] = React.useState({})

    const clearError = createClearError(errors, setErrors)

    const t1 = trans('forgot_password')
    const t2 = trans('reset_password')

    useEffect(() => {
        document.body.classList.add('page-login')
        return () => {
            document.body.classList.remove('page-login')
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (step === 1) {
            apiPost('reset-password', {email}).then(response => {
                if (response.sent === RESET_PASSWORD_SENT_TEMPORARY) {
                    setLoginPage({
                        email,
                        message: 'Ideiglenes jelszó elküldve az Ön e-mail címére.',
                    })
                    setError('')
                    setErrors({})
                    history.push('/login')
                } else {
                    setMessage(t2('send_success'))
                    setStep(2)
                    setError('')
                    setErrors({})
                }
            }).catch(({error, errors}) => {
                setError(error)
                setErrors(errors)
            })
        } else {
            apiPost('confirm-password', {email, code, password, password_confirmation}).then(() => {
                setLoginPage({
                    email,
                    message: t2('reset_success'),
                })
                setError('')
                setErrors({})
                history.push('/login', {replace: true});
            }).catch(({error, errors}) => {
                setError(error)
                setErrors(errors)
            })
        }
    };

    const resendCode = (event) => {
        event.preventDefault();

        setMessage('')

        apiPost('reset-password', {email}).then(() => {
            setMessage(t2('send_success'))
            setError('')
            setErrors({})
        }).catch(({error, errors}) => {
            setError(error)
            setErrors(errors)
        })
    };

    if (step === 1) {
        return (
            <div className="wrapper">
                <section>
                    <LogoBox/>
                    <form className="form form--bordered" onSubmit={handleSubmit}>
                        <LinkTo className="link-back" to="/login">
                            <ArrowBackIcon/>
                        </LinkTo>

                        <span className="form-title form-title--inline">{t1('title')}</span>

                        <p className="text--info">{t1('info_code')}</p>

                        {error && <Alert severity="error" style={{marginBottom: '10px'}}>{error}</Alert>}
                        {message && <Alert severity="info" style={{marginBottom: '10px'}}>{message}</Alert>}

                        <TextField
                            error={!!errors.email}
                            helperText={errors.email || ""}
                            label={t1('field.email.label')}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                clearError('email')
                            }}
                            autoComplete="email"
                            autoFocus
                            fullWidth
                        />
                        <Button variant="contained" size="large" color="primary" type="submit">{t1('button.send_code')}</Button>
                    </form>
                </section>
            </div>
        )
    }

    // step 2
    return (
        <div className="wrapper">
            <section>
                <LogoBox/>
                <form className="form form--bordered" onSubmit={handleSubmit}>
                    <LinkTo className="link-back" to="/login">
                        <ArrowBackIcon/>
                    </LinkTo>

                    <span className="form-title form-title--inline">{t2('title')}</span>

                    <p className="text--info">{t2('info_code', {email})}</p>

                    {error && <Alert severity="error" style={{marginBottom: '10px'}}>{error}</Alert>}
                    {message && <Alert severity="info" style={{marginBottom: '10px'}}>{message}</Alert>}

                    <TextField
                        error={!!errors.code}
                        helperText={errors.code || ""}
                        label={t2('field.code.label')}
                        value={code}
                        onChange={(e) => {
                            setCode(e.target.value);
                            clearError('code')
                        }}
                        fullWidth
                    />

                    <TextField
                        error={!!errors.password}
                        helperText={errors.password || ""}
                        label={t2('field.password.label')}
                        type="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            clearError('password')
                        }}
                        fullWidth
                    />

                    <TextField
                        error={!!errors.password_confirmation}
                        helperText={errors.password_confirmation || ""}
                        label={t2('field.password_confirmation.label')}
                        type="password"
                        value={password_confirmation}
                        onChange={(e) => {
                            setPasswordConfirmation(e.target.value);
                            clearError('password_confirmation')
                        }}
                        fullWidth
                    />

                    <Button variant="contained" size="large" color="primary" type="submit" style={{marginBottom: '10px'}}>{t2('button.save')}</Button>

                    <Button variant="outlined" size="large" color="primary" type="button" onClick={resendCode} style={{marginTop: 0}}>{t2('button.resend_code')}</Button>
                </form>
            </section>
        </div>
    );
}
