import * as React from 'react';
import {AppBar, Button, ListItemIcon, Menu, MenuItem, Toolbar} from "@material-ui/core";
import DashboardIcon from '@material-ui/icons/Dashboard'
import GroupIcon from '@material-ui/icons/Group'
import {Check} from "@material-ui/icons";
import LanguageIcon from '@material-ui/icons/Language'
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import {NavLinkTo} from "./NavLinkTo";
import {useAuth} from "../auth";
import {useI18n} from "../i18n";

export default function Header() {
    const {logout, developer, profile} = useAuth()
    const {trans, languages, changeLanguage, currentLanguage} = useI18n()

    const t = trans('idp')

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = React.useState(null);
    const [languageChangeInProgress, setLanguageChangeInProgress] = React.useState(false);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setLanguageMenuAnchorEl(null);
    };

    const handleChangeLanguage = (key) => {
        handleClose()
        setLanguageChangeInProgress(true)
        changeLanguage(key, true).finally(() => {
            setLanguageChangeInProgress(false)
        });
    };

    const handleLanguageMenuOpen = (event) => {
        setLanguageMenuAnchorEl(event.currentTarget);
    };

    return (
        <div className="header-container">
            <AppBar position="static">
                <Toolbar>
                    <div className="col-left">
                        <div className="header-logo-box">
                            <NavLinkTo to="/dashboard">
                                <img src="/img/logo-small.png" alt="SURVIOT APP"/>
                            </NavLinkTo>
                        </div>

                        <div className="main-nav">
                            <NavLinkTo to="/dashboard">
                                <DashboardIcon/>
                                {t('dashboard.navigation')}
                            </NavLinkTo>

                            {developer && <>
                                <NavLinkTo to="/users">
                                    <GroupIcon/>
                                    {t('user.list.navigation')}
                                </NavLinkTo>
                            </>}
                        </div>
                    </div>

                    <div className="col-right">
                        <Button
                            className="button-profile"
                            aria-label="account of current user"
                            aria-controls="menu-appbar-2"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <span className="profile-character">{profile.initials}</span>
                            <span className="profile-name" title={profile.email}>{profile.name}</span>
                        </Button>

                        <Menu
                            id="menu-appbar-2"
                            className="profile-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            transformOrigin={{
                                vertical: -50,
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {languages.length > 1 && <MenuItem onClick={handleLanguageMenuOpen}>
                                <ListItemIcon><LanguageIcon/></ListItemIcon> {t('language.menu')} <ArrowForwardIcon/>
                            </MenuItem>}
                            <MenuItem onClick={logout}>
                                <ListItemIcon><ExitToAppIcon/></ListItemIcon> {t('logout')}
                            </MenuItem>
                        </Menu>

                        <Menu
                            id="menu-appbar-3"
                            className="profile-menu"
                            anchorEl={languageMenuAnchorEl}
                            keepMounted
                            transformOrigin={{
                                vertical: -50,
                                horizontal: 'left',
                            }}
                            open={Boolean(languageMenuAnchorEl)}
                            onClose={handleClose}
                        >
                            {languages.map(language => {
                                return <MenuItem disabled={languageChangeInProgress} key={language.key} onClick={handleChangeLanguage.bind(this, language.key)}>
                                    {language.key === currentLanguage ? <ListItemIcon><Check/></ListItemIcon> : <ListItemIcon/>} {language.name}
                                </MenuItem>
                            })}
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
};
