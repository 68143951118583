import React from "react"
import {Link} from "@material-ui/core"
import {NavLink as RouterNavLink} from 'react-router-dom'

export function NavLinkTo(props) {
    const CustomLink = React.forwardRef((linkProps, ref) => (
        <RouterNavLink
            ref={ref}
            activeclassname="active"
            isactive={props.isActive}
            to={props.to}
            {...linkProps}
        />
    ))

    return <Link {...props} component={CustomLink}/>
}
